import React, { useEffect } from "react"
import { PageProps, graphql } from "gatsby"
import { Layout } from "../components/atoms/Layout"
import { SEO } from "../components/atoms/SEO"
import Hero from "../components/organisms/ComingSoon/Hero/Hero"
import { Gallery } from "../components/organisms/Series"
import ImageWithTextContact from "../components/organisms/ComingSoon/ImageWithTextContact/ImageWithTextContact"
import TwoImagesWithText from "../components/organisms/ComingSoon/TwoImagesWithText/TwoImagesWithText"
import useTealiumView from "../hooks/Tealium/useTealiumView"
import { useTealiumContext } from "../contexts/Tealium"
import TabbedImageModule from "../components/molecules/TabbedImageModule/TabbedImageModule"

const ComingSoonTemplate: React.FC<PageProps> = ({ data, location }: any) => {
  const { page } = data
  const { handleTealView } = useTealiumView()
  const { setVehicleTealData } = useTealiumContext()

  useEffect(() => {
    handleTealView({
      page_name: `${page.seriesFamily}_${page.seriesName.toLowerCase()}`,
      page_type: "preview a vehicle",
      tealium_event: "model_preview",
      vehicle_model: page.seriesName || "",
      vehicle_model_truncated: page.seriesName || "",
      vehicle_year: page?.seriesYear?.toString() || "",
      vehicle_segment_vehicle_page: page.seriesFamily || "",
      vehicle_powertrain: page.seriesPowerTrain || "",
    })
    setVehicleTealData({
      vehicle_model: page.seriesName || "",
      vehicle_model_truncated: page.seriesName || "",
      vehicle_year: page?.seriesYear?.toString() || "",
      vehicle_segment_vehicle_page: page.seriesFamily || "",
      vehicle_powertrain: page.seriesPowerTrain || "",
    })
  }, [])
  return (
    <Layout>
      <SEO
        url={location.href}
        title={page.title}
        keywords={page.seo?.keywords}
        schema={page.seo?.schema}
        description={page.seo?.description}
        canonicalPath={page.seo?.canonicalPath}
      />
      <SanitySections data={page} />
    </Layout>
  )
}
const SanitySections = ({ data }: any): JSX.Element => {
  return (
    <>
      {data?.content?.map((section: any) => {
        switch (section?._type) {
          case "comingSoonHero":
            return <Hero {...section} />
          case "gallery":
            return <Gallery {...section} />
          case "tabbedImageModule":
            return <TabbedImageModule {...section} />
          case "imageWithTextContact":
            return <ImageWithTextContact {...section} />
          case "twoImagesWithText":
            return <TwoImagesWithText {...section} />
        }
      })}
    </>
  )
}

export const query = graphql`
  query ComingSoonPageQuery($id: String!) {
    page: sanityPageComingSoon(id: { eq: $id }) {
      id
      _id
      title
      seriesName
      seriesYear
      seriesPowerTrain
      seriesFamily
      seo {
        _type
        keywords
        canonicalPath
        description
        schema
      }
      content {
        ...GallerySection
        ...ComingSoonHeroData
        ...TabbedImageModuleData
        ...ImageWithTextContactData
        ...TwoImagesWithTextData
      }
      disclaimers {
        code
        disclaimer
      }
    }
  }
`
export default ComingSoonTemplate
