import { motion } from "framer-motion"
import tw from "twin.macro"
import { Button, ButtonLink } from "../../../atoms/Button"
import { Image } from "../../../atoms/Image"
import { parseDisclaimerBlocks } from "../../../../helpers"
import { toggleDisclaimersModal } from "../../../../contexts/Disclaimers/actions"
import { useContext } from "react"
import { LanguageContext } from "../../../../contexts/Language"
import { DisclaimersContext } from "../../../../contexts/Disclaimers"
import {
  openContactDealerModal,
  updateField,
} from "../../../../contexts/Contact/actions"
import { ContactContext } from "../../../../contexts/Contact"
import { ImageWithTextContactProps } from "./ImageWithTextContact.d"
import useTealiumEvent from "../../../../hooks/Tealium/useTealiumEvent"
import { useTealiumContext } from "../../../../contexts/Tealium"

const ImageWithTextContact: React.FC<ImageWithTextContactProps> = ({
  headingImage,
  heading,
  textBody,
  image,
  sectionSlug,
  imagePosition = "left",
  seriesName,
}) => {
  const { _ } = useContext(LanguageContext)
  const [{}, dispatch] = useContext(DisclaimersContext)
  const [_state, _dispatch] = useContext(ContactContext)
  const { trackTealEvent } = useTealiumEvent()
  const { vehicleTealData } = useTealiumContext()

  return (
    <section
      css={[
        tw`grid grid-cols-1 overflow-hidden pb-[200px] mb-[-200px]`,
        tw`lg:(grid-cols-2 auto-rows-fr overflow-visible)`,
      ]}
      id={sectionSlug?.current}
      aria-label="Featured Section"
    >
      <motion.div
        css={[
          tw`bg-white col-span-1 flex gap-8 flex-col justify-end items-start px-8 pt-12 pb-8 z-40`,
          tw`md:(justify-center px-20 py-20 order-2)`,
          tw`lg:(justify-center px-20 py-10 order-2)`,
          imagePosition === "right" && tw`lg:(order-1)`,
        ]}
        initial={{ opacity: 0, x: 0 }}
        whileInView={{
          opacity: 1,
          x: 0,
        }}
        transition={{ duration: 0.25 }}
        viewport={{ once: true, amount: 0.35 }}
      >
        <Image imageData={headingImage?.image} css={tw`mr-1 w-[444px]`} />
        {heading && (
          <h2
            css={[
              tw`font-semibold text-lg md:(text-3xl max-w-[30rem]) 2xl:(max-w-full)`,
            ]}
          >
            {parseDisclaimerBlocks(heading, selection =>
              dispatch(toggleDisclaimersModal(selection))
            )}
          </h2>
        )}
        {textBody && (
          <div css={[tw`desktop-up:(max-w-[43rem]) desktop-hd:(max-w-full)`]}>
            {parseDisclaimerBlocks(textBody, selection =>
              dispatch(toggleDisclaimersModal(selection))
            )}
          </div>
        )}
        <Button
          secondary
          onClick={() => {
            _dispatch(
              updateField(
                "comments",
                _("I'm interested in the upcoming") +
                  " " +
                  vehicleTealData.vehicle_year +
                  " " +
                  vehicleTealData.vehicle_model +
                  "."
              )
            ),
              _dispatch(
                openContactDealerModal(
                  null,
                  {
                    series: seriesName,
                  },
                  "Coming Soon",
                  "ContactDealer"
                )
              )
            trackTealEvent({
              tealium_event: "contact_dealer_rest",
              vehicle_model: vehicleTealData.vehicle_model || "",
              vehicle_model_truncated: vehicleTealData.vehicle_model || "",
              vehicle_year: vehicleTealData.vehicle_year.toString() || "",
              vehicle_segment_vehicle_page:
                vehicleTealData.vehicle_segment_vehicle_page || "",
              vehicle_powertrain: vehicleTealData.vehicle_powertrain || "",
            })
          }}
          analytics-id={`image with text::${heading}`}
        >
          {_("Contact Dealer")}
        </Button>
      </motion.div>
      <div
        css={[
          tw`relative col-span-1 overflow-hidden`,
          tw` w-full h-full`,
          imagePosition === "right" && tw`md:(order-1)`,
        ]}
      >
        <Image
          imageData={image?.image}
          noPreview
          css={[tw` object-cover object-center`]}
        />

        <motion.div
          initial={{
            width: "100%",
          }}
          whileInView={{
            width: 0,
          }}
          viewport={{ once: true, amount: 0.35 }}
          transition={{ duration: 0.25 }}
          css={[tw`absolute bottom-0 h-full z-20 bg-white`]}
        />

        <motion.div
          initial={{
            width: "100%",
          }}
          whileInView={{
            width: 0,
          }}
          viewport={{ once: true, amount: 0.35 }}
          transition={{ delay: 0.5, duration: 0.25 }}
          css={[tw`absolute bottom-0 h-full z-10 bg-black`]}
        />

        <div
          css={[
            tw`block absolute bottom-0 w-full h-full z-10`,
            tw`md:(hidden)`,
          ]}
        ></div>
      </div>
    </section>
  )
}

export default ImageWithTextContact
