import tw from "twin.macro"
import { TwoImagesWithTextProps } from "./TwoImagesWithText.d"
import BlockContent from "../../../atoms/BlockContent"
import { Image } from "../../../atoms/Image"
import { parseDisclaimerBlocks } from "../../../../helpers"
import { toggleDisclaimersModal } from "../../../../contexts/Disclaimers/actions"
import { useContext } from "react"
import { ContactContext } from "../../../../contexts/Contact"

const TwoImagesWithText: React.FC<TwoImagesWithTextProps> = ({
  heading,
  body,
  bgColor,
  image1,
  image2,
  mobileImage1,
  mobileImage2,
}) => {
  const [_state, dispatch] = useContext(ContactContext)

  return (
    <div
      css={[
        tw`lg:min-h-[45rem] text-white lg:(px-12 py-20 h-full flex flex-col justify-center items-center)`,
        `${bgColor.hex && `background: ${bgColor.hex}`}`,
      ]}
    >
      <div css={[tw`grid grid-cols-1 lg:grid-cols-7 max-w-7xl mx-auto`]}>
        <div css={[tw`col-span-1 lg:(col-span-3)`]}>
          <div
            css={[
              tw`flex flex-row gap-5 overflow-x-scroll p-8 scrollbar-hide w-full max-h-96 lg:(flex-col max-h-full justify-center items-center)`,
            ]}
          >
            {image1?.image && (
              <Image
                imageData={image1?.image}
                css={[
                  tw`hidden lg:(flex h-60 w-60 lg:(h-full w-full) rounded-lg object-cover)`,
                ]}
              />
            )}
            {image2?.image && (
              <Image
                imageData={image2?.image}
                css={[tw`hidden lg:(flex h-60 w-full rounded-lg object-cover)`]}
              />
            )}
            {mobileImage1?.image && (
              <Image
                imageData={mobileImage1?.image}
                css={[tw`h-60 w-60 rounded-lg object-cover lg:(hidden)`]}
              />
            )}
            {mobileImage2?.image && (
              <Image
                imageData={mobileImage2?.image}
                css={[tw`h-60 w-full rounded-lg object-cover lg:(hidden)`]}
              />
            )}
          </div>
        </div>
        <div
          css={[
            tw`col-span-1 p-8 flex flex-col gap-5 lg:(col-span-4  px-20 justify-center items-start)`,
          ]}
        >
          <h2 css={[tw`text-3xl font-semibold text-left`]}>
            {" "}
            {parseDisclaimerBlocks(heading, selection =>
              dispatch(toggleDisclaimersModal(selection))
            )}
          </h2>
          <BlockContent data={body} />
        </div>
        <div css={[tw`col-span-1 lg:col-span-4`]}></div>
      </div>
    </div>
  )
}

export default TwoImagesWithText
