import tw from "twin.macro"
import { ComingSoonHeroProps } from "./Hero.d"
import { Button } from "../../../atoms/Button"
import {
  openContactDealerModal,
  updateField,
} from "../../../../contexts/Contact/actions"
import { useContext, useEffect, useState } from "react"
import { ContactContext } from "../../../../contexts/Contact"
import { LanguageContext } from "../../../../contexts/Language"
import { detectMobileView, parseDisclaimerBlocks } from "../../../../helpers"
import useTealiumEvent from "../../../../hooks/Tealium/useTealiumEvent"
import { useTealiumContext } from "../../../../contexts/Tealium"
import { motion } from "framer-motion"
import { toggleDisclaimersModal } from "../../../../contexts/Disclaimers/actions"

const ComingSoonHero: React.FC<ComingSoonHeroProps> = ({
  headline,
  heroImage,
  heroImageMobile,
  introText,
  bgColor,
}) => {
  const [_state, dispatch] = useContext(ContactContext)
  const { _ } = useContext(LanguageContext)
  const [heroImg, setHeroImg] = useState(heroImage)
  const { trackTealEvent } = useTealiumEvent()
  const { vehicleTealData } = useTealiumContext()
  useEffect(() => {
    const isMobileView = detectMobileView()
    if (isMobileView) {
      setHeroImg(heroImageMobile)
    } else {
      setHeroImg(heroImage)
    }
  }, [])

  return (
    <div
      css={[
        tw`grid grid-cols-1 lg:grid-cols-7 lg:h-[45rem] bg-cover bg-center bg-no-repeat relative`,
        heroImg.image.asset &&
          `background-image:url(${heroImg.image.asset.gatsbyImageData.images.fallback.src});`,
      ]}
    >
      <div
        css={[
          tw`hidden lg:(flex justify-end items-center col-span-3 px-10 text-center font-semibold gap-2)`,
          `background: ${`${bgColor?.hex}bd`};`,
        ]}
      ></div>
      <div
        css={tw`absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 max-w-screen-2xl grid grid-cols-5`}
      >
        <div
          css={[
            tw`hidden lg:(flex justify-end items-center col-span-2 px-10 mr-16 text-center font-semibold gap-2) xl:mr-20`,
          ]}
        >
          <motion.div
            animate={{ opacity: 1, x: 0, scale: 1 }}
            initial={{ opacity: 0, x: -30, scale: 0.95 }}
            transition={{ duration: 1, delay: 1 }}
            css={[tw`flex flex-col`]}
          >
            <h3 css={[tw`text-lg`]}>
              {parseDisclaimerBlocks(introText, selection =>
                dispatch(toggleDisclaimersModal(selection))
              )}
            </h3>
            <h1 css={[tw`text-6xl flex lg:text-5xl xl:text-6xl`]}>
              {parseDisclaimerBlocks(headline, selection =>
                dispatch(toggleDisclaimersModal(selection))
              )}
            </h1>
            <Button
              primary
              onClick={() => {
                dispatch(
                  updateField(
                    "comments",
                    _("I'm interested in the upcoming") +
                      " " +
                      vehicleTealData.vehicle_year +
                      " " +
                      vehicleTealData.vehicle_model +
                      "."
                  )
                ),
                  dispatch(
                    openContactDealerModal(
                      null,
                      null,
                      "Coming Soon",
                      "ContactDealer",
                      true
                    )
                  )
                trackTealEvent({
                  tealium_event: "contact_dealer_rest",
                  vehicle_model: vehicleTealData.vehicle_model || "",
                  vehicle_model_truncated: vehicleTealData.vehicle_model || "",
                  vehicle_year: vehicleTealData.vehicle_year.toString() || "",
                  vehicle_segment_vehicle_page:
                    vehicleTealData.vehicle_segment_vehicle_page || "",
                  vehicle_powertrain: vehicleTealData.vehicle_powertrain || "",
                })
              }}
              css={[tw`mt-8 w-auto lg:(w-min whitespace-nowrap mx-auto)`]}
              analytics-id="contact:hero:"
            >
              {_("Contact Dealer")}
            </Button>
          </motion.div>
        </div>
      </div>
      <div
        css={[
          tw`flex flex-col justify-center items-center col-span-3 p-10 text-center font-semibold gap-2 mt-[45rem] lg:hidden`,
          `background: ${`${bgColor?.hex}`};`,
        ]}
      >
        {" "}
        <h3 css={[tw`text-lg`]}>
          {" "}
          {parseDisclaimerBlocks(introText, selection =>
            dispatch(toggleDisclaimersModal(selection))
          )}
        </h3>
        <h1 css={[tw`text-5xl`]}>
          {parseDisclaimerBlocks(headline, selection =>
            dispatch(toggleDisclaimersModal(selection))
          )}
        </h1>
        <Button
          primary
          onClick={() => {
            dispatch(
              openContactDealerModal(
                null,
                null,
                "Coming Soon",
                "ContactDealer",
                true
              )
            )
            trackTealEvent({
              tealium_event: "contact_dealer_rest",
              vehicle_model: vehicleTealData.vehicle_model || "",
              vehicle_model_truncated: vehicleTealData.vehicle_model || "",
              vehicle_year: vehicleTealData.vehicle_year.toString() || "",
              vehicle_segment_vehicle_page:
                vehicleTealData.vehicle_segment_vehicle_page || "",
              vehicle_powertrain: vehicleTealData.vehicle_powertrain || "",
            })
          }}
          css={[tw`mt-8 w-full`]}
          analytics-id="contact:hero:"
        >
          {_("Contact Dealer")}
        </Button>
      </div>
    </div>
  )
}

export default ComingSoonHero
